import "../src/playback-flash";

import { Atom, defAtom } from "@thi.ng/atom";

import { start } from "@thi.ng/hdom";
import { div } from "@thi.ng/hiccup-html";
import { ClientMode } from "../../../api";
import { audioPolyfill } from "../../../audio/audioPolyfill";
import {
  ensureAudioContextIsResumed,
  ensureMuteSwitchIsBypassed,
} from "../../../audio/ensureAudio";
import { IPlayResult } from "../../../audio/playBuffer";
import { midiToFrequency } from "../../../midi/midiToFrequency";
import { IMessage } from "../../../sync/api";
import { Musician } from "../../../sync/Musician";
import { findDo } from "../../../utils/findDo";
import { addSaveWatch, loadLocalState } from "../../../utils/localState";
import { AudioHandler } from "../src/audio/AudioHandler";
import { credits } from "../src/components/credits";
import { nameComponent } from "../src/components/name";
import { status } from "../src/components/performance/status";
import { presets } from "../src/components/presets";
import { roomPositionFactory } from "../src/components/room-position";
import { title } from "../src/components/title";
import { chorusFactory } from "../src/components/widgets/chorus";
import { delayVerbFactory } from "../src/components/widgets/delayverb";
import { envelopeFactory } from "../src/components/widgets/envelope";
import { harmonicsFactory } from "../src/components/widgets/harmonics";
import { lfoFactory } from "../src/components/widgets/lfo";
import { playbackConfig } from "../src/components/widgets/playback";
import { vowelFactory } from "../src/components/widgets/vowel";
import { waveformHandlesFactory } from "../src/components/widgets/waveform-handles";
import {
  defWrappedWidget,
  IAppConfig,
  IAppContext,
  IAppState,
  MAX_HEIGHT,
  Widget,
  WrappedWidget,
} from "./api";

audioPolyfill();

const audioContext = new AudioContext();
ensureAudioContextIsResumed(audioContext);
ensureMuteSwitchIsBypassed();

export class App {
  public config: IAppConfig;
  public state: Atom<IAppState>;
  public ctx: IAppContext;
  public audio!: AudioHandler;

  protected widgets: WrappedWidget[];
  protected positionWidget?: Widget;

  protected static FORCE_RENDER_EVENT_LABEL = "FORCE_RENDER_EVENT_LABEL";

  constructor(config: IAppConfig) {
    if (config.isSaveEnabled) {
      loadLocalState<IAppState>(
        config.localStorageKey,
        config.localStorageVersion,
        (loadedState) => (config.initialState = loadedState),
      );
    }

    this.config = config;
    this.state = new Atom(this.config.initialState || {});

    this.ctx = {
      audioContext,
      config,
      state: this.state,
      mode: defAtom<ClientMode>("normal"),
      musician: config.isLive
        ? new Musician(
            this.config.serverUrl,
            () => audioContext.currentTime,
            this.config.initialState.musicianState,
          )
        : undefined,
      loadPreset: (preset) => {
        this.state.resetIn(["audio"], preset.preset);

        for (const widget of this.widgets) {
          widget.triggerRender();
        }
      },
    };

    this.audio = new AudioHandler(this.ctx);

    addSaveWatch(this.state, config.localStorageKey, config.localStorageVersion, {
      transform: (state) => ({
        ...state,
        musicianState: {
          ...state.musicianState,
          isArmed: false,
          bufferLoadingStates: {},
        },
      }),
    });

    this.widgets = [
      defWrappedWidget(this.ctx, envelopeFactory(this.ctx, this.audio), "Envelope", this.audio),
      defWrappedWidget(
        this.ctx,
        waveformHandlesFactory(this.ctx, this.audio),
        "Waveform",
        this.audio,
      ),
      defWrappedWidget(this.ctx, harmonicsFactory(this.ctx, this.audio), "Harmonics", this.audio),
      defWrappedWidget(this.ctx, vowelFactory(this.ctx, this.audio), "Vowel Filter", this.audio),
      defWrappedWidget(this.ctx, lfoFactory(this.ctx, this.audio), "LFO", this.audio),
      defWrappedWidget(this.ctx, chorusFactory(this.ctx, this.audio), "Chorus", this.audio),
      defWrappedWidget(
        this.ctx,
        delayVerbFactory(this.ctx, this.audio),
        "Reverb and Delay",
        this.audio,
      ),
    ];

    if (this.config.isLive) {
      this.positionWidget = roomPositionFactory(this.ctx, this.audio);
    }
    this.setupCursorDependents();
  }

  public start() {
    start(this.rootComponent(), { root: this.config.domRoot, ctx: this.ctx });
  }

  protected updateBufferLoadingProgress(url: string, value: number) {
    if (this.ctx.musician === undefined) {
      return;
    }

    const states = this.ctx.musician.bufferLoadingStates;
    states[url] = value;
    this.ctx.musician.bufferLoadingStates = states;
  }

  protected rootComponent() {
    return () => {
      const mode = this.ctx.mode.deref();
      const mainComponent =
        mode === "normal" ? div({}, ...this.widgets.map((widget) => widget.cmp())) : null;

      const positionWidget =
        this.positionWidget !== undefined
          ? (() => {
              const room = this.config.room;
              const heightConstraint = screen.availHeight - 200;
              const widthConstraint = (screen.availWidth - 10) * (room.y_m / room.x_m);
              const positionHeight = Math.min(heightConstraint, widthConstraint, MAX_HEIGHT) | 0;
              const positionWidth = (positionHeight * (room.x_m / room.y_m)) | 0;

              return this.positionWidget.cmp(positionWidth, positionHeight);
            })()
          : null;

      return [
        "div",
        [title],
        [status],
        [nameComponent],
        positionWidget,
        [presets],
        mainComponent,
        [credits],
        ["span.version", {}, this.config.localStorageVersion],
      ];
    };
  }

  protected setupCursorDependents() {
    if (this.ctx.musician !== undefined) {
      this.ctx.musician.onNote = (synth, note, startTimeS, durationS, gain) => {
        const noteStartTimeS = startTimeS - this.ctx.audioContext.currentTime;

        if (synth === "Sampler") {
          // const stopAction = this.audioHandler.playBuffer(bufferKey, startTimeS, durationS, underhangS, gain);
          // this.audioHandler.playBuffer(note, startTimeS, durationS, gain);
          // TODO store stopAction?
        } else {
          const isRemote = true;
          this.audio.playNote(noteStartTimeS, midiToFrequency(note), gain, durationS, isRemote);
        }
      };

      addSaveWatch(
        this.ctx.musician.state,
        this.config.localStorageKey,
        this.config.localStorageVersion,
        {
          transform: (musicianState) => ({
            ...this.state.deref(),
            musicianState,
          }),
        },
      );

      this.ctx.musician.onLoadBuffersMessage = (urls) => {
        urls.forEach((url) => {
          this.audio
            .loadBuffer(url, (event) => {
              const progress = event.loaded / event.total;
              this.updateBufferLoadingProgress(url, progress);
            })
            .then(() => {
              this.updateBufferLoadingProgress(url, 1.0);
            })
            .catch(() => {
              this.updateBufferLoadingProgress(url, 0.0);
            });
        });
      };

      interface IPlayed {
        id: IMessage["id"];
        result: IPlayResult;
      }
      const playResults: IPlayed[] = [];

      this.ctx.musician.onPlayBuffer = async (messageId, url, absoluteStartTimeS, gain) => {
        const isRemote = true;
        const playResult = await this.audio.playBuffer(url, absoluteStartTimeS, gain, isRemote);

        if (playResult !== undefined) {
          playResults.push({ id: messageId, result: playResult });
        }
      };

      this.ctx.musician.onStopBuffer = (playMessageId) => {
        findDo(
          playResults,
          (played) => played.id === playMessageId,
          (played) => played.result.stopAction(this.ctx.audioContext.currentTime),
        );
      };

      this.ctx.musician.onClientModeMessage = (clientMode) => {
        this.ctx.mode.reset(clientMode);
      };

      this.ctx.musician.onControlsVisibleMessage = (visible) => {
        playbackConfig.controlsVisible = visible;
      };
    }
  }
}
