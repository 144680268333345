import { appleCoventGarden } from "../../../rooms/appleCoventGarden";
import { getDeviceAudioLatencyMs, getDeviceVisualLatencyMs } from "../../../utils/getDevice";
import { getServerUrl } from "../../../utils/getServerUrl";
import { IAppConfig } from "../app-main/api";
import { creditData } from "./credit-data";
import presets from "./presets";

// main App configuration
export const CONFIG: IAppConfig = {
  title: "Design Sound",
  room: appleCoventGarden,
  credits: creditData,

  localStorageKey: "instore_client_app_state",
  localStorageVersion: "30",
  isSaveEnabled: true,
  isLive: true,
  serverUrl: getServerUrl("musician"),

  // DOM root element (or ID)
  domRoot: "app",

  // initial app state
  initialState: {
    musicianState: {
      name: "",
      channelId: undefined,
      position: { x: 0.5, y: 0.5 },
      serverOffsetMs: 0,
      visualLatencyMs: getDeviceVisualLatencyMs(navigator.userAgent),
      audioLatencyMs: getDeviceAudioLatencyMs(navigator.userAgent),
      bufferLoadingStates: {},
      isArmed: false,
    },
    audio: presets[0].preset,
    presets,
  },
};
